:root {
  --greenClr: #068b5a;
  --blueClr: #146EBE;
  --redClr: #E44434;
}

.app {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.title-opsi-saran {
  font-size: 1.5em;
  font-weight: 500;
}

.thanks-opsi-saran {
  font-size: 1.7em;
  font-weight: 600;
}

.pesan-otomatis {
  font-size: 1.2em;
  font-weight: 400;
}