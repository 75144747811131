/* body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  min-height: 500px;
  background: #f5f5f5;
  text-align: center;
}

section {
  padding: 100px;
  box-sizing: border-box;
  height: 100%;

}

p {
  margin: 40px 0;
} */


/* Buttons */

/* input {
  display: none;
}

label {
  background: #FFCD00;
  border: 0;
  color: #B57700;
  padding: 15px;
  min-width: 60px;
  font-size: 18px;
  font-weight: bold;
  margin: 10px
} */


/* Smiley */

.smiley {
  background: linear-gradient(135deg, rgb(255, 233, 25) 0%, rgb(251, 192, 0) 100%);
  border-radius: 100%;
  padding: 25px;
  position: relative;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(calc(-50% - 121px));
  /* box-shadow: rgba(211, 165, 110, 0.498039) 0px 30px 30px 1px, rgb(245, 245, 245) 0px 20px 10px 1px; */
}


.mouth {
  width: 60%;
  height: 30%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  bottom: 18%;
  left: 50%;
  margin-left: -30%;
  background: #B57700;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}


.eyes {
  width: 100%;
  margin-top: 15%;
  box-sizing: border-box;
  padding: 0 5px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.eyes .eye {
  width: 20px;
  height: 20px;
  background: #B57700;
  float: left;
  border-radius: 100%;
  position: relative;
}

.eyes .eye:nth-of-type(2) {
  float: right;
}

.eyes .eye::after {
  content: "";
  display: block;
  position: absolute;
  width: 0%;
  height: 0%;
  background: #fed800;
  transform: rotate(0deg);
  top: -15px;
  left: 5px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.eyes .eye:first-of-type::after {
  transform: rotate(0deg);
  left: auto;
  right: 5px;
}

/* Normal animation */
/* .smiley.normal .mouth,
#normal[type=radio]:checked~.smiley .mouth {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 10%;
  width: 40%;
  bottom: 25%;
  margin-left: -20%;
}

.smiley.normal .eyes,
#normal[type=radio]:checked~.smiley .eyes {
  margin-top: 30%
} */


/* angry animation */
/* .smiley.angry .mouth,
#angry[type=radio]:checked~.smiley .mouth {
  width: 40%;
  height: 20%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  bottom: 18%;
  left: 50%;
  margin-left: -20%;
  border-bottom: 0;
}

.smiley.angry .eyes,
#angry[type=radio]:checked~.smiley .eyes {
  margin-top: 35%
}

.smiley.angry .eye::after,
#angry[type=radio]:checked~.smiley .eye::after {
  width: 120%;
  height: 50%;
  transform: rotate(-35deg);
  top: -3px;
  left: -5px;
  border-radius: 0;
}

.smiley.angry .eye:first-of-type::after,
#angry[type=radio]:checked~.smiley .eye:first-of-type::after {
  transform: rotate(35deg);
  left: auto;
  right: -5px;
} */

/* */

.smiley .mouth {
  animation: move-mouth-down 6s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}


@keyframes move-mouth-down {
  0% {
    bottom: 18%;
  }

  4.55% {
    bottom: 16%;
  }

  8.45% {
    bottom: 16%;
  }

  13% {
    bottom: 18%;
  }

  100% {
    bottom: 18%;
  }
}


.smiley .eyes {
  animation: move-eyes-down 6s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}


@keyframes move-eyes-down {
  0% {
    margin-top: 15%;
  }

  4.55% {
    margin-top: 19%;
  }

  8.45% {
    margin-top: 19%;
  }

  13% {
    margin-top: 15%;
  }

  100% {
    margin-top: 15%;
  }

}



.smiley .eye:nth-of-type(2) {
  height: 20px;
  margin-top: 0;
  animation: wink 6s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}


@keyframes wink {
  0% {
    height: 20px;
    margin-top: 0;
  }

  3.9% {
    height: 3px;
    margin-top: 8px;
  }

  9.1% {
    height: 3px;
    margin-top: 8px;
  }

  13% {
    height: 20px;
    margin-top: 0;
  }

  100% {
    height: 20px;
    margin-top: 0;
  }
}



/* .smiley.normal .eye,
#normal[type=radio]:checked~.smiley .eye {
  height: 20px;
  margin-top: 0;
  animation: blink 6s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}


@keyframes blink {
  0% {
    height: 20px;
    margin-top: 0;
  }

  3.25% {
    height: 2px;
    margin-top: 8px;
  }

  6.5% {
    height: 20px;
    margin-top: 0;
  }

  9.75% {
    height: 2px;
    margin-top: 8px;
  }

  13% {
    height: 20px;
    margin-top: 0;
  }

  100% {
    height: 20px;
    margin-top: 0;
  }
}
 */



/* .smiley.angry .eyes,
.smiley.angry .mouth,
#angry[type=radio]:checked~.smiley .eyes,
#angry[type=radio]:checked~.smiley .mouth {
  animation: move-angry-head 6s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
} */




@keyframes move-angry-head {
  0% {
    transform: translateX(0%);
  }

  2.6% {
    transform: translateX(-20%);
  }

  5.2% {
    transform: translateX(15%);
  }

  7.8% {
    transform: translateX(-10%);
  }

  10.4% {
    transform: translateX(5%);
  }

  13% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(0%);
  }
}